import { IonLoading } from "@ionic/react";
import React, { Suspense } from "react";
const IndexPage = React.lazy(() => import("./pages/IndexPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const RegisterPage = React.lazy(() => import("./pages/RegisterPage"));
const PrivatePages = React.lazy(() => import("./PrivatePages"));
const GetStarted = React.lazy(() => import("./pages/GetStarted"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const ForgetPassword = React.lazy(() => import("./pages/ForgetPasswordPage"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));

export const IndexPageRoute = () => {
  return (
    <Suspense fallback={<IonLoading isOpen={false} />}>
      <IndexPage />
    </Suspense>
  );
};

export const LoginPageRoute = () => {
  return (
    <Suspense fallback={<IonLoading isOpen={false} />}>
      <LoginPage />
    </Suspense>
  );
};

export const RegisterPageRoute = () => {
  return (
    <Suspense fallback={<IonLoading isOpen={false} />}>
      <RegisterPage />
    </Suspense>
  );
};

export const PrivatePagesRoute = () => {
  return (
    <Suspense fallback={<IonLoading isOpen={false} />}>
      <PrivatePages />
    </Suspense>
  );
};

export const GetStartedPageRoute = () => {
  return (
    <Suspense fallback={<IonLoading isOpen={false} />}>
      <GetStarted />
    </Suspense>
  );
};

export const PrivacyPolicyRoute = () => {
  return (
    <Suspense fallback={<IonLoading isOpen={false} />}>
      <PrivacyPolicy />
    </Suspense>
  );
};

export const ContactUsRoute = () => {
  return (
    <Suspense fallback={<IonLoading isOpen={false} />}>
      <ContactUs />
    </Suspense>
  );
};

export const AboutUsRoute = () => {
  return (
    <Suspense fallback={<IonLoading isOpen={false} />}>
      <AboutUs />
    </Suspense>
  );
};

export const ForgetPasswordPageRoute = () => {
  return (
    <Suspense fallback={<IonLoading isOpen={false} />}>
      <ForgetPassword />
    </Suspense>
  );
};

export const NotFoundPageRoute = () => {
  return (
    <Suspense fallback={<IonLoading isOpen={false} />}>
      <NotFoundPage />
    </Suspense>
  );
};
