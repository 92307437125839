import React, { useContext, useEffect, useState } from "react";
import { auth as firbaseAuth, firebaseAnalytics } from "./firebase";

interface Auth {
  loggedIn: boolean;
  userId?: string;
}

interface AuthInit {
  loading: boolean;
  auth?: Auth;
}

export const AuthContext = React.createContext<Auth>({ loggedIn: false });

export function useAuth(): Auth {
  return useContext(AuthContext);
}

export function useAuthInit(): AuthInit {
  const [authInit, setAuthInit] = useState<AuthInit>({
    loading: true,
  });

  useEffect(() => {
    return firbaseAuth.onAuthStateChanged((firebaseUser) => {
      const auth = firebaseUser
        ? { loggedIn: true, userId: firebaseUser.uid }
        : { loggedIn: false };
      setAuthInit({ loading: false, auth });

      // Setting analytics
      if (firebaseUser && firebaseUser.uid) {
        firebaseAnalytics
          .setUserId({
            userId: firebaseUser.uid,
          })
          .catch(() => {
            console.log("Analytics Error while setting userID");
          });
      }
    });
  }, []);
  return authInit;
}
