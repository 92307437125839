import { SplashScreen } from "@capacitor/splash-screen";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

/* Tachyons */
import "./css/tachyons.min.css";

async function registerWorker() {
  try {
    console.log("Registering service worker");
    const registration = await navigator.serviceWorker.register(
      "./firebase-messaging-sw.js",
      { scope: "/" }
    );
    console.log("Registered service worker");
    await navigator.serviceWorker.ready;

    if (registration && registration.pushManager) {
      console.log("subscribing calls");
      await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey:
          "BNp_0wW25gg8LIJP-fBOzz71WheIRzeaYtP1ZktfCaW3jiOmUodzKQkq6nwrfHhEJqwrdhT-bDAeVo-HFuxegZ8",
      });
    }
  } catch (e) {
    console.log("Error on registering service worker");
  }
}

if ("serviceWorker" in navigator) {
  console.log("Registering service worker");
  registerWorker().catch((error) => console.error(error));
}

ReactDOM.render(<App />, document.getElementById("root"));
SplashScreen.hide();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

try {
  serviceWorker.register();
} catch (e) {
  console.log("Error while registering service worker");
}

// Use matchMedia to check the user preference
const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
toggleDarkTheme(prefersDark.matches);
// Listen for changes to the prefers-color-scheme media query
prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));
// Add or remove the "dark" class based on if the media query matches
function toggleDarkTheme(shouldAdd) {
  document.body.classList.toggle("dark", shouldAdd);
}
