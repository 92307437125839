import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { isPlatform } from "@ionic/react";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  vapidKey: process.env.REACT_APP_VAPID_KEY,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
    merge: true,
  });
  firebase
    .firestore()
    .enablePersistence()
    .catch((err) => {
      console.log("Enabiling persistence failed");
    })
    .then(() => {
      console.log("Offline persistence enabled");
    });
}

if (!isPlatform("capacitor")) {
  FirebaseAnalytics.initializeFirebase(firebaseConfig)
    .then(() => {
      console.log("Web analytics initialized from init");
      firebaseAnalytics.setCollectionEnabled({ enabled: true }).catch(() => {
        console.log("Analytics enable failed");
      });
    })
    .catch((e) => {
      console.log("Error while initialize analytics init", e);
    });
}

export default firebase;
export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const firebaseAnalytics = FirebaseAnalytics;
export const analytics = firebase.analytics();
