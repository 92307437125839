import { IonApp, IonRouterOutlet, IonLoading, useIonAlert } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { App as IonicApp } from "@capacitor/app";
import { AuthContext, useAuthInit } from "./auth";
import "@firebase/messaging";
import "../src/css/app.css";
import {
  AboutUsRoute,
  ContactUsRoute,
  ForgetPasswordPageRoute,
  GetStartedPageRoute,
  IndexPageRoute,
  LoginPageRoute,
  NotFoundPageRoute,
  PrivacyPolicyRoute,
  PrivatePagesRoute,
  RegisterPageRoute,
} from "./Routes";
import { AdMob } from "@capacitor-community/admob";

const App: React.FC = () => {
  const { auth, loading } = useAuthInit();
  const [presentAlert, dismissAlert] = useIonAlert();

  IonicApp.addListener("backButton", ({ canGoBack }) => {
    if (!canGoBack) {
      presentAlert({
        header: "Do you want to exit?",
        message: "",
        buttons: [
          { text: "Yes", handler: (d) => IonicApp.exitApp() },
          { text: "No", handler: (d) => dismissAlert() },
        ],
        backdropDismiss: true,
      });
    }
  });

  useEffect(() => {
    AdMob.initialize({
      requestTrackingAuthorization: true,
    });
  }, []);

  if (loading) {
    return <IonLoading isOpen={true} />;
  }

  return (
    <IonApp>
      <AuthContext.Provider value={auth}>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route exact path="/">
              <IndexPageRoute />
            </Route>
            <Route exact path="/login">
              <LoginPageRoute />
            </Route>
            <Route exact path="/register">
              <RegisterPageRoute />
            </Route>
            <Route exact path="/get-started">
              <GetStartedPageRoute />
            </Route>
            <Route exact path="/forget-password">
              <ForgetPasswordPageRoute />
            </Route>
            <Route path="/app">
              <PrivatePagesRoute />
            </Route>
            <Route exact path="/home">
              <IndexPageRoute />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicyRoute />
            </Route>
            <Route exact path="/contact-us">
              <ContactUsRoute />
            </Route>
            <Route exact path="/about-us">
              <AboutUsRoute />
            </Route>
            <Route>
              <NotFoundPageRoute />
            </Route>
          </IonRouterOutlet>
        </IonReactRouter>
      </AuthContext.Provider>
    </IonApp>
  );
};

export default App;
